import { Channel } from "./channel";
class LoadedIndicator {
    constructor() {
        Object.defineProperty(this, "_fileName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_allSizes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_oneBox", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1
        });
        Object.defineProperty(this, "_allBox", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 10
        });
    }
    init(name, allSizes) {
        this._fileName = name;
        this._allSizes = allSizes;
        this._oneBox = this._allSizes / this._allBox;
        this.start();
    }
    start() {
        const fileName = this._fileName;
        const allBox = this._allBox;
        Channel.changeIndicators({ fileName, allBox, status: "start" });
    }
    progress(receivedSizes) {
        const oneBox = this._oneBox;
        console.log(receivedSizes, oneBox);
        Channel.changeIndicators({ progress: Math.floor((receivedSizes / oneBox)), status: "progress" });
    }
    finish() {
        Channel.changeIndicators({ status: "finish" });
    }
}
export { LoadedIndicator };
