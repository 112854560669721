import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
const Error = React.forwardRef(() => {
    return (_jsx("div", { style: {
            color: "white",
            fontSize: "150px",
            margin: "200px",
        }, className: "", children: "Error" }));
});
export { Error };
