import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import "./styles/index.scss";
import { CircularProgress } from "@mui/material";
function LoadIndicator(props) {
    return _jsxs(Box, { sx: {
            position: "absolute",
            bottom: "150px",
            right: "50px",
            display: "flex",
            flexFlow: "column",
            alignItems: "center"
        }, children: [_jsxs(Box, { sx: {
                    marginBottom: "25px"
                }, children: [_jsx(Typography, { sx: {
                            textShadow: "0px 0px 3px black",
                            fontSize: "20px"
                        }, variant: "myText", children: props.file }), ";"] }), _jsxs(Box, { sx: { position: "relative", display: "inline-flex" }, children: [_jsx(CircularProgress, { variant: "determinate", value: props.progress * 10 }), _jsx(Box, { sx: {
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }, children: _jsxs(Typography, { variant: "caption", component: "div", color: "text.secondary", children: [props.progress * 10, " %"] }) })] })] });
}
export { LoadIndicator };
