const styles = {
    wrapper: {
        paddingTop: "15vh",
        textAlign: "center",
        paddingBottom: "25px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
    },
};
const inputStyles = {
    "720": {
        backgroundColor: "white",
        paddingLeft: "5px",
        color: "grey",
        width: "250px",
    },
    "520": {
        paddingLeft: "5px",
        width: "80%",
        backgroundColor: "white",
        height: "60px",
        marginBottom: "20px",
        fontSize: "2em",
        color: "grey",
    },
};
export { styles, inputStyles };
