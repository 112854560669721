const styles = {
    main: {
        bgcolor: "background.paper",
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        textAlign: "center",
    },
};
export { styles };
